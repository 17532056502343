import { SOCIAL_LIST } from 'src/constants/layout';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import styled from 'styled-components';

const WidgetFollow = ({ eventDataLayer = {} }) => {
  return (
    <>
      <WidgetWrapper id='widget-follow' className='widget-follow'>
        {SOCIAL_LIST.map((item, index) => (
          <a
            target='_blank'
            key={index}
            href={item.link}
            rel='noopener nopopopener nofollow'
            aria-label='social'
            title='social'
            //! DATALAYER
            onClick={() =>
              sendDataLayerGlobal({
                ...eventDataLayer,
                data: {
                  title: item?.name,
                  heading: item?.name,
                  index: index + 1
                }
              })
            }
          >
            <div className='bg-item'>{item.icon}</div>
          </a>
        ))}
      </WidgetWrapper>
    </>
  );
};

const WidgetWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-left: 15px;
  }
  a:first-child {
    margin-left: 0px;
  }
  .bg-item {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      color: rgba(0, 0, 0, 1);
    }
  }
  #X {
    padding: 2px;
  }
`;
export default WidgetFollow;
